



















































































































































































































































































































.mobile
  .code
    display none
  .column.container
    margin 32px
  .cta
    background-color #fafafa
    padding 16px 8px
    position fixed
    left 0
    right 0
    bottom 0
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
.cta
  padding 8px
.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .6)
  overflow hidden
  background-image url('~assets/backgrounds/train.jpg')
  background-position: 0 30%;
  background-size: cover;
  text-overflow: clip;
.column.container
  margin-top -64px
.q-card
  width 80vw
  max-width 600px
.center
  justify-content center
  display flex
