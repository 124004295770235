

















































.q-card
  max-width 600px
  position relative
