






































































































































































.layout-padding
  @media (max-width: 1199px)
    padding 1em
    padding-top 2em
.layout-padding
  max-width 768px

h6
  margin 16px 0
