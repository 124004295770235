.pricing {
  border-style: solid;
  border-color: #e0e0e0;
  border-radius: 0 !important;
  border-width: 0;
}
@media (min-width: 1024px) {
  .pricing {
    border-left-width: 1px;
  }
}
@media (max-width: 600px) {
  .pricing {
    border-top-width: 1px;
  }
}
@media (max-width: 600px) {
  .mobile-block {
    display: block;
  }
}
@media (min-width: 1024px) {
  .bubble-change,
  .bubble-top,
  .bubble-line,
  .bubble-bottom {
    position: relative;
    padding-left: 8px;
  }
  .bubble-change:before,
  .bubble-top:before,
  .bubble-line:before,
  .bubble-bottom:before,
  .bubble-change:after,
  .bubble-top:after,
  .bubble-line:after,
  .bubble-bottom:after {
    position: absolute;
    content: "";
    background-color: #dcdcdc;
  }
  .bubble-change:before,
  .bubble-top:before,
  .bubble-bottom:before {
    width: 8px;
    height: 8px;
    left: -12px;
    border-radius: 100%;
  }
  .bubble-change:before {
    top: 6px;
    border-radius: 0;
    transform: rotate(45deg);
  }
  .bubble-top:before {
    bottom: 8px;
  }
  .bubble-bottom:before {
    top: 8px;
  }
  .bubble-line:after {
    width: 2px;
    top: -8px;
    bottom: -8px;
    left: -9px;
  }
}
/*# sourceMappingURL=src/pages/ondemand/train/results/train-card.css.map */