.item {
  padding-left: 0;
  height: auto;
}
.name {
  font-size: 14px;
  color: #3367ab;
  cursor: pointer;
}
.name:hover {
  text-decoration: underline;
}
.sub {
  font-size: 13px;
}
/*# sourceMappingURL=src/pages/ondemand/train/summary/ticket.css.map */