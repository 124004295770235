@media (max-width: 1199px) {
  .layout-padding {
    padding: 1em;
    padding-top: 2em;
  }
}
.layout-padding {
  max-width: 768px;
}
h6 {
  margin: 16px 0;
}
/*# sourceMappingURL=src/pages/ondemand/train/tickets/index.css.map */