

















































































































































































































.q-card
  max-width 600px
  .desktop &
    width 600px
.q-radio.flex
  display flex !important // Override display: inline-flex
.help-notice
  max-width 620px
  margin 16px auto
