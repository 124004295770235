.q-card {
  max-width: 600px;
}
.desktop .q-card {
  width: 600px;
}
.q-radio.flex {
  display: flex !important;
}
.help-notice {
  max-width: 620px;
  margin: 16px auto;
}
/*# sourceMappingURL=src/pages/ondemand/train/options/index.css.map */