






































































































































































































































  .overview-price
    padding 8px
    .total
      font-size 1em
    .price
      margin 4px 0
      font-size 1.6em
    .people
      font-size 0.8em
  .q-item-sublabel
    text-align center
    margin-bottom 15px
  .times
    padding 30px
    padding-top 0
    justify-content center
    display flex
    button
      &:first-of-type
        margin-right 15px

.time-button
  width 86px
