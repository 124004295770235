



















































































































































































.layout-padding
  max-width 768px
button
  width 100%
span
  margin-top 16px
.hr
  margin-top 24px
  margin-bottom 10px

.item
  padding-left 0

.delivery-text
  margin-bottom 0

.adults
  padding-bottom 0
  min-height: 0

.adult-text
  font-size: 14px!important
