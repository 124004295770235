














































































































































































































































































.layout-padding
  @media (max-width: 1199px)
    padding 1em
.layout-padding
  max-width 720px
  padding 1rem

.vertical-rule
  max-width 1px !important
  margin-top 27px
  margin-bottom 7px
  background #c6c7c4

.horizontal-rule
  margin-left 8px
  margin-right 8px
  padding-bottom 5px
  border-bottom 1px solid #c6c7c4

.loading
  background rgba(244,244,244, .5)
  position absolute
  top 0
  right 150px
  bottom 0
  left 150px
  svg
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

.earlier-later
  width 100%
  display flex
  justify-content space-around

.time-button
  width 86px
